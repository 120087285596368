<template>
    <section class="centered-page pt-10 pb-10 text-white">
        <h1 class="title mb-5">PARTNERS</h1>
        <div class="flex justify-content items-center">
          <img class="partner-img" src="images/coingecko.webp">
          <img class="partner-img" src="images/dex.webp">
          <img class="partner-img" src="images/raydium.webp">
          <img class="partner-img" src="images/solana.png">
          <img class="partner-img" src="images/phantom.webp">
        </div>
    </section>
</template>

<script>
export default {
  name: 'PartnersPage',
};
</script>

<style scoped>
section {
  background-color: black;
}

.title {
  font-size: 1.8rem; /* Adjust size as needed */
  color: #FFF; /* Adjust color as needed */
  font-weight: bold;
}

.centered-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partner-img {
  height: 60px;
  margin: 10px 40px;
}
</style>