<template>
  <!-- Dex Screener Button -->
  <button
    @click="
      openSocialMediaLink(
        'https://www.pump.fun/4FLiUqgfEQFERXc6s9haEJ6euFsk56eYNUxDkvSTvBbn'
      )
    "
    class="bg-transparent p-2 px-5 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:hover:bg-gray-600 rounded-lg"
    aria-label="Pump"
  >
    <img
      src="images/pumpbw.png"
      alt="Description of the image"
      class="icon-black-and-white"
    />
  </button>
</template>

<style scoped>
.icon-black-and-white {
  width: 32px;
  height: 32px;
}
</style>

<script>
export default {
  methods: {
    openSocialMediaLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
