<template>
  <section
    id="howbuy"
    class="section how-buy-container bg-light-purple justify-center pt-40 pb-40"
  >
    <h1 class="title flex justify-center mb-5 text-4xl font-bold text-white">
      HOW TO BUY
    </h1>
    <div class="grid-container">
      <div class="block justify-center text-center">
        <div class="img flex justify-center">
          <img class="partner-img" src="images/phantom.webp" />
        </div>
        <div class="text">
          <h3 class="subtitle flex justify-center">Phantom Wallet</h3>
          <p>
            Install Phantom Wallet using the app store on your phone or the
            computer via a browser extension.
          </p>
        </div>
      </div>
      <div class="block justify-center text-center">
        <div class="img flex justify-center">
          <img class="partner-img" src="images/solana.png" />
        </div>
        <div class="text">
          <h3 class="subtitle">Purchase Solana</h3>
          <p>
            Add SOL tokens to your wallet. If you don't have any you can top up
            using Phantom or through an exchange.
          </p>
        </div>
      </div>
      <div class="block justify-center text-center">
        <div class="img flex justify-center">
          <img class="partner-img" src="images/pump.svg" />
        </div>
        <div class="text">
          <h3 class="subtitle text-center">Pump Fun</h3>
          <p>
            Go to
            <a
              href="https://www.pump.fun/4FLiUqgfEQFERXc6s9haEJ6euFsk56eYNUxDkvSTvBbn"
              >Pump.Fun</a
            >
            and connect your wallet in the top right.
          </p>
        </div>
      </div>
      <div class="block justify-center text-center">
        <div class="img flex justify-center">
          <img class="partner-img" src="images/pump.svg" />
        </div>
        <div class="text">
          <h3 class="subtitle text-center">Buy</h3>
          <p>
            Select "Buy" to the right of the graph, input the amount of SOL and
            simply click "place trade".
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<script>
export default {
  name: "HowBuyPump",
};
</script>

<style scoped>
a {
  color: blue;
}

.copy-text {
  font-size: small;
}

.img {
  margin-bottom: 4px;
}

.partner-img {
  height: 50px;
  width: auto;
}

.title {
  color: #fff; /* Adjust color as needed */
  font-weight: bold;
}

.address {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 20px; /* Adjust the value as needed for the desired roundness */
  background-color: #fff; /* Set your desired background color */
  color: #333; /* Set your desired text color */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
}

.address:hover {
  background-color: #e0e0e0; /* Set your desired background color on hover */
}

.subtitle {
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* Adjust the minmax values as needed */
  gap: 20px;
  padding: 20px 5%;
}

.block {
  background-color: white;
  align-items: center; /* Center items vertically */
  gap: 5px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.how-buy-container {
  background-image: linear-gradient(
    to right,
    #baa1d5,
    #c0b0d8,
    #c7bfda,
    #d0cddb,
    #dbdbdb
  );
}

/* Add other styles as needed */
</style>
