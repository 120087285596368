<template>
  <div
    id="roadmap"
    class="roadmap-container min-h-screen pt-40 pb-40"
    ref="roadmap"
  >
    <div class="text-center py-8">
      <h1 class="text-4xl font-bold text-white test">Our Roadmap</h1>
    </div>

    <div class="flex flex-col grid-cols-9 mx-auto md:grid">
      <template v-for="(event, index) in events" :key="index">
        <div v-if="index % 2 === 0" class="flex md:contents flex-row-reverse">
          <div
            class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto fixed-height font-cyber"
            :class="{ 'animate-fade-right': isVisible }"
            :style="{ animationDelay: `${index * 1}s` }"
          >
            <h3 class="gradient-text text-lg font-semibold lg:text-xl">
              {{ event.title }}
            </h3>
            <ul class="mt-2 leading-6 list-disc">
              <li
                v-for="(item, itemIndex) in event.description"
                :key="`item-${itemIndex}`"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto">
            <div class="flex items-center justify-center w-6 h-full">
              <!-- line color -->
              <div
                class="w-1 h-full rounded-t-full bg-white"
              ></div>
            </div>
            <!-- cirlce color -->
            <div
              class="absolute w-6 h-6 -mt-3 bg-dark-purple border-4 border-white rounded-full top-1/2" 
            ></div>
          </div>
        </div>

        <div v-else class="flex md:contents">
          <div class="relative col-start-5 col-end-6 mr-7 md:mx-auto">
            <div class="flex items-center justify-center w-6 h-full">
              <div class="w-1 h-full bg-white"></div>
            </div>
            <div
              class="absolute w-6 h-6 -mt-3 bg-dark-purple border-4 border-white rounded-full top-1/2"
            ></div>
          </div>
          <div
            class="relative p-4 my-6 text-gray-800 bg-white rounded-xl col-start-6 col-end-10 mr-auto fixed-height"
            :class="{ 'animate-fade-left': isVisible }"
            :style="{ animationDelay: `${index * 1}s` }"
          >
            <h3 class="gradient-text text-lg font-semibold lg:text-xl">
              {{ event.title }}
            </h3>
            <ul class="mt-2 leading-6 list-disc">
              <li
                v-for="(item, itemIndex) in event.description"
                :key="`item-${itemIndex}`"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadMap",
  data() {
    return {
      events: [
        {
          title: "Phase 1",
          description: [
            "Website development",
            "Launch on Pumpfun",
            "Community building",
          ],
        },
        {
          title: "Phase 2",
          description: [
            "Launch on Raydium",
            "Launch SolBet Beta",
            "Strategic Marketing",
            "CoinMarketCap Listing",
            "CoinGecko Listing",
          ],
        },
        {
          title: "Phase 3",
          description: [
            "Airdrop for Beta testers and holders",
            "Launch SolBet Mobile app",
            "CEX Listing",
          ],
        },
        {
          title: "Phase 4",
          description: ["Official launch of SolBet", "Major Partnership"],
        },
      ],
      isVisible: false,
    };
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.isVisible = entry.isIntersecting;
      });
    });
    observer.observe(this.$refs.roadmap);
  },
};
</script>

<style scoped>
.roadmap-container {
  /* background-image: linear-gradient(to left top, #34224c, #371f58, #3a1b65, #3d1671, #3f0f7e); */
  background-image: radial-gradient(circle, #baa1d5, #ab93c7, #9d85ba, #8f77ac, #816a9f, #7b639a, #745c95, #6e5590, #6d5391, #6d5193, #6c4f94, #6b4d95);
}
.animate-fade-right,
.animate-fade-left,
.animate-fade {
  animation-duration: 1s;
  animation-fill-mode: both;
}


.animate-fade-right {
  animation-name: fadeRight;
}

.gradient-text {
  /* Define the gradient */
  background: linear-gradient(
    to right top,
    #baa1d5,
    #8f7ca5,
    #675878,
    #41374d,
    #1f1926
  );
  /* Set text color to transparent to allow gradient to show through */

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Ensure compatibility with older browsers */
  -webkit-text-fill-color: transparent;
}

.animate-fade-left {
  animation-name: fadeLeft;
}

.animate-fade {
  animation-name: fadeIn;
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fixed-height {
  width: 300px; /* Adjust the height as needed */
  box-shadow: 3px 3px 0.5px #6d5193;
}

ul {
  padding-left: 15px;
}

/* .test {
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
} */
</style>
