<template>
  <div id="app" class="snap-y snap-mandatory overflow-scroll h-screen">
    <NavBar />
    <TitlePage />
    <AboutUs />
    <HowBuyPump />
    <!-- <HowBuy /> -->
    <RoadMap />
    <SignUp />
    <PartnersPage />
    <FooterPage />
  </div>
</template>

<script>
import AboutUs from './components/AboutUs.vue';
import NavBar from './components/NavBar.vue';
import RoadMap from './components/RoadMap.vue';
import TitlePage from './components/TitlePage.vue';
import PartnersPage from './components/PartnersPage.vue';
import FooterPage from './components/FooterPage.vue';
// import HowBuy from './components/HowBuy.vue';
import SignUp from './components/SignUp.vue';
import HowBuyPump from './components/HowBuyPump.vue';

export default {
  name: "App",
  components: {
    NavBar,
    TitlePage,
    AboutUs,
    RoadMap,
    PartnersPage,
    FooterPage,
    // HowBuy,
    SignUp,
    HowBuyPump
  },
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
}
</style>
