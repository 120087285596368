<template>
  <section
    id="features"
    ref="featuresSection"
    class="about-us-container min-h-screen pt-40 pb-40"
  >
    <h1
      class="text-4xl font-bold text-white flex justify-center initial-hidden"
    >
      Games
    </h1>
    <div class="betting-types flex justify-center py-8 text-center">
      <div class="type initial-hidden">
        <h3 class="gradient-text subtitle">Sports</h3>
        <p>
          Users can place bets on various sports events happening globally. The
          platform provides real-time data through reliable oracles, ensuring
          fair and accurate outcomes. Users can bet on a wide range of sports,
          including but not limited to football, basketball, soccer, tennis, and
          more. The escrow system guarantees that bets are securely held until
          the event's outcome is determined, eliminating the need for trust
          between parties.
        </p>
      </div>
      <div class="type initial-hidden">
        <h3 class="gradient-text subtitle">Esports PVP Wagering</h3>
        <p>
          Enthusiasts of competitive gaming can participate in
          player-versus-player (PvP) wagering on popular esports titles such as
          Dota 2, League of Legends, Counter-Strike: Global Offensive (CS:GO),
          and more. The platform integrates with reputable esports data
          providers to deliver real-time match statistics and results. Users can
          wager on their favorite players or teams, adding an extra layer of
          excitement to esports competitions.
        </p>
      </div>
      <div class="type initial-hidden">
        <h3 class="gradient-text subtitle">Online Casino</h3>
        <p>
          The online casino section offers a diverse range of classic and modern
          casino games, including slots, blackjack, roulette, poker, and more.
          Each game operates on transparent and verifiable smart contracts,
          ensuring fairness and trustworthiness. Players can enjoy an immersive
          casino experience with seamless gameplay and instant payouts
          facilitated by Solana's high throughput and low latency network.
        </p>
      </div>
    </div>
    <h1
      class="text-4xl font-bold text-white flex justify-center pb-5 initial-hidden"
    >
      Features
    </h1>
    <div class="feature-types text-center initial-hidden">
      <div class="right align-center">
        <img class="logo-img" src="images/phone.png" />
      </div>
      <div class="left">
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">Live Forum Chat Rooms</h3>
          <p class="paragraph">
            Engage in live discussions, share insights, and stay updated on
            trends within the cryptocurrency gambling community.
          </p>
        </div>
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">
            Comprehensive Referral System
          </h3>
          <p class="paragraph">
            Invite friends to join and earn rewards with our referral program,
            fostering a sense of community and rewarding loyal users.
          </p>
        </div>
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">Personalized Analytics</h3>
          <p class="paragraph">
            Gain valuable insights into your betting performance with in-depth
            analytics, helping you optimize your strategy and improve your
            chances of success.
          </p>
        </div>
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">Expanding Game Selection</h3>
          <p class="paragraph">
            Enjoy a diverse range of betting options with our constantly
            expanding selection of games, including e-sports, sports, and casino
            classics betting.
          </p>
        </div>
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">
            Free Monthly Lottery Events
          </h3>
          <p class="paragraph">
            Participate in our monthly lottery events for a chance to win
            exciting prizes and rewards.
          </p>
        </div>
        <div class="type-small initial-hidden">
          <h3 class="gradient-text subtitle-small">In-App Casino Games</h3>
          <p class="paragraph">
            Access a variety of casino games directly within our app, providing
            convenient and immersive gameplay on the go.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
  mounted() {
    this.observeSection();
  },
  methods: {
    observeSection() {
      const featuresSection = this.$refs.featuresSection;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const elements =
              featuresSection.querySelectorAll(".initial-hidden");
            elements.forEach((el) => {
              if (entry.isIntersecting) {
                el.classList.add("text-animate-enter");
              }
            });
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(featuresSection);
    },
  },
};
</script>

<style>
.about-us-container {
  padding: 0 5%;
  background-image: linear-gradient(
    to right bottom,
    #34224c,
    #371f58,
    #3a1b65,
    #3d1671,
    #3f0f7e
  );
}

.type {
  background-color: white;
  align-items: center; /* Center items vertically */
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  max-width: 600px;
}

.type-small {
  background-color: white;
  align-items: center; /* Center items vertically */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 600px;
}

.feature-types {
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  margin: 20px;
}

.gradient-text {
  font-weight: bold;
  /* Define the gradient */
  background: linear-gradient(
    to right top,
    #baa1d5,
    #8f7ca5,
    #675878,
    #41374d,
    #1f1926
  );

  /* Set text color to transparent to allow gradient to show through */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Ensure compatibility with older browsers */
  -webkit-text-fill-color: transparent;
}

.paragraph {
  font-size: 1rem;
}

.subtitle-small {
  font-size: 1.1rem;
}

/* animations */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.initial-hidden {
  opacity: 0;
}

.text-animate-enter {
  animation: fadeUp 2s forwards;
}
</style>
