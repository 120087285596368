<template>
  <nav class="bg-dark-grey fixed w-full z-20 top-0 start-0">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
    >
      <img
        src="images/transparentlogoandtypo.png"
        class="h-8"
        alt="SolBet Logo"
      />
      <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
        <!-- Social Icons Start -->
        <!-- Telegram Button -->
        <button
          @click="openSocialMediaLink('https://t.me/solbetofficial')"
          class="text-white bg-transparent p-2 px-5 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:hover:bg-gray-600"
          aria-label="Telegram"
        >
          <span class="[&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 496 512"
            >
              <path
                d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"
              />
            </svg>
          </span>
        </button>
        <!-- Twitter Button -->
        <button
          @click="openSocialMediaLink('https://twitter.com/SolBetOfficial')"
          class="text-white bg-transparent p-2 px-5 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:hover:bg-gray-600"
          aria-label="Twitter"
        >
          <span class="[&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 512 512"
            >
              <path
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
              />
            </svg>
          </span>
        </button>
        <!-- <DexScreener /> -->
        <PumpButton />
        <button
          @click="scrollToSection('howbuy')"
          type="button"
          class="grad-button text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Buy $BET
        </button>
        <button
          @click="isNavCollapsed = !isNavCollapsed"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-light-grey focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-light-grey dark:focus:ring-gray-600"
          aria-controls="navbar-sticky"
          :aria-expanded="isNavCollapsed ? 'true' : 'false'"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <div
        :class="{ hidden: !isNavCollapsed, flex: isNavCollapsed }"
        class="items-center justify-between w-full md:flex md:w-auto md:order-1"
        id="navbar-sticky"
      >
        <ul
          class="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-light-grey md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-dark-grey dark:bg-light-grey md:dark:bg-light-grey dark:border-gray-700"
        >
          <li v-for="link in links" :key="link.name">
            <a
              @click="scrollToSection(link.id)"
              href="#"
              class="block py-2 px-3 text-white rounded md:hover:bg-transparent md:hover:text-light-purple md:p-0"
              >{{ link.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from "vue";

const isNavCollapsed = ref(false);

const links = ref([
  { id: "home", name: "Home" },
  { id: "features", name: "Features" },
  { id: "roadmap", name: "Roadmap" },
  { id: "signup", name: "Sign Up" },
]);

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<script>
import PumpButton from "./PumpButton.vue";
export default {
  name: "NavBar",
  components: {
    //DexScreener,
    PumpButton,
  },
  methods: {
    openSocialMediaLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.grad-button {
  background-image: linear-gradient(
    to right,
    #baa1d5,
    #a388c5,
    #8b6fb5,
    #7357a5,
    #5a4096
  );
}
</style>
