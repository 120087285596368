<template>
  <section class="bg-black centered-page pt-20 pb-40 text-white text-center">
    <div class="footer flex justify-center">
      <img class="logo-img" src="images/transparentlogoandtypo.png" />
    </div>
    <div class="paragraph pb-10">
      <p>All Rights Reserved &copy; 2024 SolBet</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
section {
  padding: 20px 5%;
}

.footer {
    align-items: center;
}
.logo-img {
  height: 35px;
}

.paragraph {
  font-size: 0.8rem;
  color: #fff;
  padding-top: 20px;
}
</style>
