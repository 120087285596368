<template>
  <section
    id="home"
    ref="animatedSection"
    class="section flex justify-center text-center title-page-container text-white min-h-screen animate-on-scroll"
  >
    <div class="title-content">
      <div class="top-component">
        <div class="text-content">
          <h1 class="title bg-gradient-to-r from-fuchsia-500 to-cyan-500">
            Solana's First Decentralized Gambling Platform
          </h1>
          <h2 class="subtitle bg-gradient-to-r from-cyan-500 to-fuchsia-500">
            Sports Betting, Online Casino & Esports Wagering
          </h2>
          <p class="paragraph">
            Experience the thrill of crypto betting - where blockchain meets
            chance!
          </p>
        </div>
        <img class="logo-img" src="images/appleipad.png" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TitlePage",
  mounted() {
    const section = this.$refs.animatedSection;
    const textElements = section.querySelectorAll(".text-content"); // Adjust selector as needed
    const imageElement = section.querySelector(".logo-img"); // Adjust selector as needed

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add entering animations
            textElements.forEach((el) =>
              el.classList.add("text-animate-enter")
            );
            textElements.forEach((el) =>
              el.classList.remove("text-animate-leave")
            );
            imageElement.classList.add("image-animate-enter");
            imageElement.classList.remove("image-animate-leave");
          } else {
            // Add leaving animations
            textElements.forEach((el) =>
              el.classList.add("text-animate-leave")
            );
            textElements.forEach((el) =>
              el.classList.remove("text-animate-enter")
            );
            imageElement.classList.add("image-animate-leave");
            imageElement.classList.remove("image-animate-enter");
          }
        });
      },
      { threshold: 0.9 }
    );

    observer.observe(this.$refs.animatedSection);
  },
};
</script>

<style scoped>
.title-page-container {
  height: 60vh;
  padding: 0 5%;
  padding-top: 100px;
}

.title-content {
  display: grid;
  padding-top: 60px;
}

.title {
  font-size: 2.5rem; /* Adjust size as needed */
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Ensure compatibility with older browsers */
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.3rem; /* Adjust size as needed */
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Ensure compatibility with older browsers */
  -webkit-text-fill-color: transparent;
}

.paragraph {
  font-size: 1rem;
  padding-top: 20px;
  color: black;
}

.second-component {
  margin-top: 20px;
}

/* animations */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(300px);
  }
}

@keyframes rollIn {
  from {
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  to {
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes rollOut {
  from {
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
  to {
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
}

.text-animate-enter {
  animation: fadeUp 2s forwards;
}

.text-animate-leave {
  animation: fadeDown 3s forwards;
}

.image-animate-enter {
  animation: rollIn 1s forwards;
}

.image-animate-leave {
  animation: rollOut 1s forwards;
}
</style>
