<template>
  <section id="signup" ref="signup" class="pt-10 pb-10">
    <h1 class="title flex justify-center mb-3">Ready to Start Betting?</h1>
    <h1 class="subtitle flex justify-center mb-3">
      Sign up for the beta and become eligible for airdrops
    </h1>
    <div class="flex justify-center">
      <div class="flex items-center">
        <input
          v-model="email"
          type="text"
          class="rounded-lg border border-gray-300 p-2 mr-2 w-64"
          placeholder="Enter your email address"
        />
        <button
          @click="validateEmail"
          class="bg-gradient-to-br from-purple-300 to-purple-900 text-white px-4 py-2 rounded-lg"
        >
          Sign Up
        </button>
      </div>
    </div>
    <div class="flex justify-center">
      <p class="text-red-500 mt-2" v-if="invalidEmail">
        Please enter a valid email address.
      </p>
      <p v-else-if="signedUp" class="text-green-500 mt-2">
        Thank you for signing up!
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "SignUp",
  data() {
    return {
      email: "",
      invalidEmail: false,
      signedUp: false,
    };
  },
  methods: {
    validateEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(this.email)) {
        this.signedUp = true;
        this.invalidEmail = false;
      } else {
        this.signedUp = false;
        this.invalidEmail = true;
      }
    },
  },
};
</script>

<style>
.title {
  font-size: 1.8rem; /* Adjust size as needed */
  font-weight: bold;
}

.subtitle {
  font-size: 1.3rem; /* Adjust size as needed */
}

/* Gradient Background for Button */
.bg-gradient-to-br {
  /* background: linear-gradient(to bottom right, #baa1d5, #8f7ca5, #675878, #41374d, #1f1926); */
  background: linear-gradient(
    to right,
    #baa1d5,
    #a388c5,
    #8b6fb5,
    #7357a5,
    #5a4096
  );
}
</style>
